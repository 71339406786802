import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;
axios.defaults.withCredentials = true;

export const getUsersApi = async () => {
  return await (
    await axios.get(`${BASE_URL}/user`)
  ).data;
};

export const deleteUserApi = async (userId) => {
  return await (
    await axios.delete(`${BASE_URL}/user/${userId}`)
  ).data;
};

export const updateUserApi = async ({
  userId,
  name,
  accountName,
  password,
}) => {
  return await (
    await axios.put(`${BASE_URL}/user/${userId}`, {
      name,
      accountName,
      password,
    })
  ).data;
};
