import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./routes/Home";
import Login from "./routes/Login";
import Signup from "./routes/Signup";
import Main from "./routes/Main";
import PrivateRoute from "./components/auth/PrivateRoute";
import AllVehicles from "./components/AllVehicles";
import TodayVehicle from "./components/TodayVehicle";
import Enroll from "./routes/Enroll";
import Vehicle from "./routes/Vehicle";
import VehicleModify from "./routes/VehicleModify";
import AdminHome from "./routes/AdminHome";
import Employee from "./routes/Employee";
import ChangePw from "./routes/ChangePw";
import StatusModify from "./routes/StatusModify";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route element={<PrivateRoute />}>
          <Route path="/main" element={<Main />}>
            <Route path="" element={<Navigate replace to={"all"} />} />
            <Route path="all" element={<AllVehicles />} />
            <Route path="today" element={<TodayVehicle />} />
          </Route>
          <Route path="/enroll" element={<Enroll />} />
          <Route path="/vehicle/:vehicleId" element={<Vehicle />} />
          <Route path="/modify/:vehicleId" element={<VehicleModify />} />
        </Route>
        <Route element={<PrivateRoute isAdmin={true} />}>
          <Route path="/admin" element={<AdminHome />}></Route>
          <Route path="/admin/employee" element={<Employee />}></Route>
          <Route path="/admin/changepw" element={<ChangePw />}></Route>
          <Route path="/admin/status" element={<StatusModify />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
