import React from "react";
import styled from "styled-components";
import logo from "../assets/img/logo.png";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-size: 1.125rem;
  .logoGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4rem;
    img {
      width: 10rem;
      margin-bottom: 1.5rem;
    }
    .title {
      h1 {
        text-align: center;
        font-weight: 700;
        font-size: 1.3rem;
        &:first-child {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
  .btnGroup {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    grid-row-gap: 1rem;
    button {
      border-radius: 0.4rem;
      padding: 1rem 2rem;
      font-size: 1.125rem;
      width: max-content;
      &:hover {
        cursor: pointer;
      }
    }
    button.login {
      background-color: #ece049;
      border: none;
      margin-right: 1rem;
    }
    button.signup {
      background-color: #fff;
      border: 0.5px solid #000;
    }
    button.changePwBtn {
      background-color: #ece049;
    }
    button.statusBtn {
      background-color: #fff;
      border: 0.5px solid #000;
    }
  }
`;
const Wrapper = styled.div`
  h1.admin {
    font-weight: 700;
    font-size: 1.3rem;
  }
`;

function AdminHome() {
  const navigate = useNavigate();

  return (
    <Container>
      <Wrapper>
        <div className="logoGroup">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="title">
            <h1>관리자 페이지</h1>
          </div>
        </div>
        <div className="btnGroup">
          <button
            className="login"
            onClick={() => {
              navigate("/login");
            }}
          >
            <p>전체 차량</p>
            <p>현황 보기</p>
          </button>
          <button
            className="signup"
            onClick={() => {
              navigate("/admin/employee");
            }}
          >
            <p>직원 정보</p>
            <p>조회/수정</p>
          </button>
          <button
            className="changePwBtn"
            onClick={() => {
              navigate("/admin/changepw");
            }}
          >
            <p>비밀 번호</p>
            <p>변경</p>
          </button>
          <button
            className="statusBtn"
            onClick={() => {
              navigate("/admin/status");
            }}
          >
            <p>작업 단계</p>
            <p>수정</p>
          </button>
        </div>
      </Wrapper>
    </Container>
  );
}

export default AdminHome;
