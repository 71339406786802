import React from "react";
import styled from "styled-components";
import logo from "../assets/img/logo.png";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { signupApi } from "../apis/auth";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-size: 1.125rem;
  .logoGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    img {
      width: 10rem;
      margin-bottom: 1.5rem;
    }
    h1 {
      text-align: center;
      &:first-child {
        margin-bottom: 0.5rem;
      }
    }
  }
  .formGroup {
    display: flex;
    flex-direction: column;
    input,
    button {
      ${(props) => props.theme.buttonFormat}
    }
    input {
      background-color: #f5f5f5;
      font-size: 0.9375rem;
      padding-left: 1rem;
      margin-bottom: 0.5rem;
      &:focus {
        outline: none;
      }
    }
    button {
      background-color: #fff;
      margin-top: 0.5rem;
      font-size: 1.125rem;
      border: 0.5px solid #000;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .loginLink {
    margin-top: 3rem;
    h3 {
      font-size: 0.875rem;
      font-weight: 200;
      text-align: center;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;
const Wrapper = styled.div``;

function Signup() {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  const { mutate: signUp } = useMutation(signupApi, {
    onSuccess: (data) => {
      console.log(data);
      if (data.status === "exist") {
        return alert("이미 존재하는 아이디입니다.");
      } else if (data.status === "ok") {
        alert("회원가입에 성공했습니다.");
        return navigate("/main");
      } else {
        return alert("회원가입에 실패했습니다. 다시 시도해주세요.");
      }
    },
    onError: (error) => {
      console.log(error);
      return alert("회원가입에 실패했습니다. 다시 시도해주세요.");
    },
  });

  const onSubmit = (inputData) => {
    if (!inputData.name) {
      return alert("이름을 입력해주세요.");
    }
    if (!inputData.accountName) {
      return alert("아이디를 입력해주세요.");
    }
    if (!inputData.password) {
      return alert("비밀번호를 입력해주세요.");
    }
    if (!inputData.passwordConfirm) {
      return alert("비밀번호 확인을 입력해주세요.");
    }
    if (inputData.password !== inputData.passwordConfirm) {
      return alert("비밀번호가 일치하지 않습니다.");
    }
    signUp({
      name: inputData.name,
      accountName: inputData.accountName,
      password: inputData.password,
    });
  };

  return (
    <Container>
      <Wrapper>
        <div className="logoGroup">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
        </div>
        <form className="formGroup" onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            placeholder="이름"
            {...register("name", {
              // required: true,
            })}
            autoComplete="off"
          />
          <input
            type="text"
            placeholder="아이디"
            {...register("accountName", {
              // required: true,
            })}
            autoComplete="off"
          />
          <input
            type="password"
            placeholder="비밀번호"
            {...register("password", {
              // required: true,
            })}
            autoComplete="off"
          />
          <input
            type="password"
            placeholder="비밀번호 확인"
            {...register("passwordConfirm", {
              // required: true,
            })}
            autoComplete="off"
          />
          <button type="submit" className="signup">
            회원가입
          </button>
        </form>
        <div className="loginLink">
          <h3
            onClick={() => {
              navigate("/login");
            }}
          >
            이미 계정이 있으신가요? 로그인 하러 가기
          </h3>
        </div>
      </Wrapper>
    </Container>
  );
}

export default Signup;
