import { createGlobalStyle } from "styled-components";
import { ThemeProvider } from "styled-components";
import Router from "./Router";
import Modal from "react-modal";
import { RecoilRoot } from "recoil";
import { Suspense } from "react";
import Loading from "./components/Loading";

const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, menu, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video, textarea, input, button {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  /* color: #000; */
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  ::-webkit-scrollbar {
    display: none;
  }
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}
button {
  color: #000;
}
html,
body{
width:100%;
overflow-x:hidden;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
    display: none;
}
body {
  line-height: 1;

}
menu, ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  /* font-family: 'Source Sans Pro', sans-serif; */
}
a {
  color: inherit;
  text-decoration: none;
}
input {
  box-sizing: border-box;
}
`;

const theme = {
  keyColor: "rgb(239,135,132)",
  bgColor: "rgb(245,243,243)",
  buttonFormat: `
      width: 15rem;
      border-radius: 0.4rem;
      padding: 1rem 2rem;
      font-size: 1.125rem;
      border: none;
  `,
  inputFormat: `
  border: 1px solid #000;
  outline: none;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  `,
  descriptionFontSize: ".8rem",
  titleFontSize: "1rem",
  bigFontSize: "1.3rem",
};

Modal.setAppElement("#root");

// if (process.env.REACT_APP_NODE_ENV === "development") {
// console = window.console || {};
// console.log = function no_console() {};
// console.warn = function no_console() {};
// console.error = function () {};
// }

function App() {
  // if (process.env.REACT_APP_NODE_ENV === "development") {

  // }
  return (
    <>
      <RecoilRoot>
        <Suspense fallback={<Loading />}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Router />
          </ThemeProvider>
        </Suspense>
      </RecoilRoot>
    </>
  );
}

export default App;
