import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { userState } from "../atom";
import { BiArrowBack } from "react-icons/bi";
import { useMutation } from "react-query";
import { logoutApi } from "../apis/auth";
import { useNavigate } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1rem;
`;
const BackBtn = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  padding: 0;
  cursor: pointer;
  .icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
`;
const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .info {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    .name {
      display: flex;
      font-weight: 200;

      margin-right: 0.5rem;
      p:last-child {
        margin-left: 0.3rem;
        strong {
          font-weight: 600;
        }
      }
    }
    #logout {
      background-color: #d9d9d9;
      border: none;
      font-size: 0.75rem;
      cursor: pointer;
      border-radius: 0.4rem;
      padding: 0.15rem 0.6rem;
    }
  }
  .adminBar {
    background-color: #ece049;
    font-size: 0.8rem;
    width: max-content;
    margin-top: 0.2rem;
    padding: 0.2rem;
    border-radius: 5px;
  }
`;

function Header({ isAdmin, hideBackBtn }) {
  const userInfo = useRecoilValue(userState);
  console.log(userInfo);
  const navigate = useNavigate();
  const setUserInfo = useSetRecoilState(userState);

  const { mutate: logout } = useMutation(logoutApi, {
    onSuccess: () => {
      setUserInfo(null);
      alert("로그아웃 되었습니다.");
      return navigate("/");
    },
  });

  const onClickBackBtn = () => {
    // 이전 페이지가 "/login"인지 확인
  };

  return (
    <Container>
      {hideBackBtn ? (
        <BackBtn>
          <BiArrowBack
            className="icon"
            onClick={onClickBackBtn}
            style={{
              visibility: "hidden",
            }}
          />
          <AiFillHome
            className="icon"
            onClick={() => {
              if (userInfo?.isAdmin) {
                navigate("/admin");
              } else {
                navigate("/main");
              }
            }}
          />
        </BackBtn>
      ) : (
        <BackBtn>
          <BiArrowBack
            className="icon"
            onClick={() => {
              navigate(-1);
            }}
          />
          <AiFillHome
            className="icon"
            onClick={() => {
              navigate("/main");
            }}
          />
        </BackBtn>
      )}
      <UserInfo>
        <div className="info">
          <div className="name">
            <p>사용자</p>
            <p>
              <strong>{userInfo?.name}</strong>
            </p>
          </div>
          <button id="logout" onClick={logout}>
            로그아웃
          </button>
        </div>
        {userInfo?.isAdmin && (
          <div className="adminBar">
            {"<"}관리자모드 사용중{">"}
          </div>
        )}
      </UserInfo>
    </Container>
  );
}

export default Header;
