import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;
axios.defaults.withCredentials = true;

export const checkLoginApi = async () => {
  return await (
    await axios.get(`${BASE_URL}/auth/checkLogin`)
  ).data;
};

export const loginApi = async ({ id, pw }) => {
  return await (
    await axios.post(`${BASE_URL}/auth/login`, {
      id,
      pw,
    })
  ).data;
};

export const logoutApi = async () => {
  return await (
    await axios.post(`${BASE_URL}/auth/logout`)
  ).data;
};

export const signupApi = async ({ accountName, password, name }) => {
  return await (
    await axios.post(`${BASE_URL}/auth/signup`, {
      accountName,
      password,
      name,
    })
  ).data;
};
