import React, { useState } from "react";
import styled from "styled-components";
import Header from "../components/Header";
import { useQuery } from "react-query";
import { getVehiclesApi } from "../apis/vehicle";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  .wrapper {
    height: 100%;
    margin-bottom: 15rem;
  }
`;
const Wrapper = styled.div`
  padding: 0 1.5rem;
  height: 100%;
`;
const Title = styled.div`
  h1 {
    font-weight: 700;
    font-size: 1.875rem;
    margin-bottom: 1rem;
  }
  .desc {
    p {
      font-size: 0.8rem;
      font-weight: 400;
      word-break: keep-all;
      &:last-child {
        margin-top: 0.3rem;
        font-weight: 200;
        line-height: 1.2;
      }
    }
  }
`;
const Contents = styled.div`
  height: 100%;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`;
const Buttons = styled.div`
  display: flex;
  button {
    background-color: #333333;
    border: none;
    color: #fff;
    width: 100%;
    cursor: pointer;
    font-size: 1.25rem;
    word-break: keep-all;
    padding: 2.5rem 1rem;
    font-weight: 700;
    &.active {
      background-color: #ece049;
      color: #000;
    }
  }
`;

function Main() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Container>
      <div className="wrapper">
        <Header hideBackBtn={true} />
        <Wrapper>
          <Title>
            <h1>
              {location.pathname === "/main/all"
                ? "전체 차량 현황"
                : "오늘의 출고 현황"}
            </h1>
            <div className="desc">
              <p>
                {location.pathname === "/main/all"
                  ? "관리중인 차량의 전체 현황을 볼 수 있습니다."
                  : "오늘의 출고 현황을 확인할 수 있습니다."}
              </p>
              <p>
                차량번호를 클릭하면 상세내용을 확인할 수 있으며, 해당 담당자는
                세부 내용을 수정할 수 있습니다.
              </p>
            </div>
          </Title>
          <Contents>
            <Outlet />
          </Contents>
        </Wrapper>
      </div>
      <Footer>
        <Buttons>
          <button
            id="allVehiclesBtn"
            className={location.pathname === "/main/all" ? "active" : ""}
            onClick={() => {
              navigate("/main/all");
            }}
          >
            <p>전체 차량</p>
            <p>현황 보기</p>
          </button>
          <button
            id="todayVehicleBtn"
            className={location.pathname === "/main/today" ? "active" : ""}
            onClick={() => {
              navigate("/main/today");
            }}
          >
            <p>오늘의</p>
            <p>출고 현황</p>
          </button>
          <button
            onClick={() => {
              navigate("/enroll");
            }}
          >
            <p>차량 정보</p>
            <p>등록하기</p>
          </button>
        </Buttons>
      </Footer>
    </Container>
  );
}

export default Main;
