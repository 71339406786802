import React from "react";
import styled from "styled-components";
import logo from "../assets/img/logo.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { checkLoginApi, loginApi, logoutApi } from "../apis/auth";
import { useForm } from "react-hook-form";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userState } from "../atom";
import Loading from "../components/Loading";
import { updateUserApi } from "../apis/user";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-size: 1.125rem;
  .logoGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6rem;
    img {
      width: 10rem;
      margin-bottom: 1.5rem;
    }
    h1 {
      text-align: center;
      &:first-child {
        margin-bottom: 0.5rem;
      }
    }
  }
  .formGroup {
    display: flex;
    flex-direction: column;
    input,
    button {
      ${(props) => props.theme.buttonFormat}
    }
    input {
      background-color: #f5f5f5;
      font-size: 0.9375rem;
      padding-left: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
      margin-bottom: 0.5rem;
      &:focus {
        outline: none;
      }
    }
    button {
      background-color: #ece049;
      margin-top: 1rem;
      font-size: 1.125rem;
    }
  }
  .signupLink {
    margin-top: 3rem;
    h3 {
      font-size: 0.875rem;
      font-weight: 200;
      text-align: center;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;
const Wrapper = styled.div``;

function ChangePw() {
  const navigate = useNavigate();
  const { register, handleSubmit, setValue } = useForm();
  const userInfo = useRecoilValue(userState);
  const setUserInfo = useSetRecoilState(userState);

  console.log(userInfo);

  const { mutate: logout } = useMutation(logoutApi);
  const { mutate: updateUser } = useMutation(updateUserApi, {
    onSuccess: (res) => {
      alert("관리자 계정 정보가 변경되었습니다.\n다시 로그인해주세요.");
      logout();
      return navigate("/");
    },
  });

  const onSubmit = (inputData) => {
    // console.log(inputData);
    if (!inputData.name) {
      return alert("이름을 입력해주세요.");
    }
    if (!inputData.id) {
      return alert("아이디를 입력해주세요.");
    } else if (!inputData.pw) {
      return alert("비밀번호를 입력해주세요.");
    }
    updateUser({
      userId: userInfo?.id,
      name: inputData.name,
      accountName: inputData.id,
      password: inputData.pw,
    });
  };

  return (
    <Container>
      <Wrapper>
        <div className="logoGroup">
          <div className="logo">
            <img src={logo} alt="logo" />
            <h1>관리자 계정 정보 변경</h1>
          </div>
        </div>
        <form className="formGroup" onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            autoComplete="off"
            placeholder="이름"
            {...register("name")}
            defaultValue={userInfo?.name}
          />
          <input
            type="text"
            autoComplete="off"
            placeholder="아이디"
            {...register("id")}
            defaultValue={userInfo?.accountName}
          />
          <input
            type="password"
            autoComplete="off"
            placeholder="비밀번호"
            {...register("pw")}
          />
          <button className="login" type="submit">
            변경하기
          </button>
        </form>
        <div className="signupLink">
          <h3
            onClick={() => {
              navigate("/signup");
            }}
          >
            계정이 없으신가요? 회원가입 하러 가기
          </h3>
        </div>
      </Wrapper>
    </Container>
  );
}

export default ChangePw;
