import React, { useEffect } from "react";
import styled from "styled-components";
import logo from "../assets/img/logo.png";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userState } from "../atom";
import { useQuery } from "react-query";
import { checkLoginApi } from "../apis/auth";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-size: 1.125rem;
  color: #000;
  .logoGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6rem;
    img {
      width: 10rem;
      margin-bottom: 1.5rem;
    }
    h1 {
      text-align: center;
      &:first-child {
        margin-bottom: 0.5rem;
      }
    }
  }
  .btnGroup {
    display: flex;
    flex-direction: column;
    button {
      width: 15rem;
      border-radius: 0.4rem;
      padding: 1rem 2rem;
      font-size: 1.125rem;
      color: #000;
      &:hover {
        cursor: pointer;
      }
    }
    button.login {
      background-color: #ece049;
      border: none;
      margin-bottom: 1rem;
    }
    button.signup {
      background-color: #fff;
      border: 0.5px solid #000;
    }
  }
`;
const Wrapper = styled.div``;

function Home() {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(userState);
  console.log(userInfo);

  const { isLoading, data } = useQuery("checkLogin", checkLoginApi, {
    onSuccess: (res) => {
      console.log("로그인되어 있음", res);
      navigate("/main");
    },
    onError: (err) => {
      console.log("로그인되어 있지 않음", err);
    },
  });

  // useEffect(() => {
  //   return navigate("/main");
  // }, [userInfo]);

  return (
    <Container>
      <Wrapper>
        <div className="logoGroup">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="title">
            <h1>르노코리아</h1>
            <h1>남동정비사업소</h1>
          </div>
        </div>
        <div className="btnGroup">
          <button
            className="login"
            onClick={() => {
              navigate("/login");
            }}
          >
            로그인
          </button>
          <button
            className="signup"
            onClick={() => {
              navigate("/signup");
            }}
          >
            회원가입
          </button>
        </div>
      </Wrapper>
    </Container>
  );
}

export default Home;
