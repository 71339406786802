import React, { useState } from "react";
import styled from "styled-components";
import Header from "../components/Header";
import { useMutation, useQuery } from "react-query";
import { getVehicleApi, updateVehicleApi } from "../apis/vehicle";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import downloadIcon from "../assets/img/download.png";
import Loading from "../components/Loading";
import AWS from "aws-sdk";
import JSZip, { folder } from "jszip";
import { saveAs } from "file-saver";
import { BsDot } from "react-icons/bs";
import { useForm } from "react-hook-form";
import axios from "axios";

const Container = styled.div`
  min-height: 100vh;
  max-width: 640px;
  padding-bottom: 5rem;
`;
const Wrapper = styled.form`
  padding: 0 1.5rem;
`;
const Title = styled.div`
  margin-bottom: 2.5rem;
  h1 {
    font-size: 1.875rem;
    font-weight: 800;
  }
`;
const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  font-size: 0.8rem;
  grid-row-gap: 1rem;
  word-break: keep-all;
  align-items: center;
  .title {
    font-weight: 700;
  }
  .content {
    font-weight: 400;
    display: flex;
    align-items: center;
    .button {
      background-color: #ece049;
      border: none;
      padding: 0.1rem 0.3rem;
      border-radius: 3px;
      font-size: 0.8rem;
      margin-left: 0.5rem;
      cursor: pointer;
      img {
        width: 0.7rem;
        margin-left: 0.3rem;
      }
    }
  }
  input,
  textarea,
  select {
    outline: none;
    font-weight: 400;
    border: 0.5px solid #000;
    padding: 0.3rem;
  }
  input,
  select {
    width: 9rem;
  }
  textarea {
    resize: none;
    height: 3.5rem;
    width: 14rem;
  }
`;
const History = styled.div`
  font-size: 0.8rem;
  margin-top: 3rem;
  .title {
    font-weight: 700;
    background-color: #ece049;
    width: max-content;
    padding: 0.2rem 0.4rem;
  }
  .content {
    background-color: #f5f5f5;
    padding: 1rem 0.5rem;
    li {
      font-weight: 400;
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      &:last-child {
        margin-bottom: 0;
      }
      .button {
        background-color: #d9d9d9;
        border: none;
        padding: 0.1rem 0.3rem;
        border-radius: 3px;
        font-size: 0.7rem;
        margin-left: 0.5rem;
        cursor: pointer;
        img {
          width: 0.7rem;
          margin-left: 0.3rem;
        }
      }
    }
  }
  .modifyBtn {
    #modifyBtn {
      background-color: #ece049;
      font-weight: 600;
      font-size: 0.8rem;
      border: none;
      padding: 0.2rem 0.4rem;
    }
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
`;

function VehicleModify() {
  const navigate = useNavigate();
  const { vehicleId } = useParams();
  const { isLoading, data: vehicle } = useQuery("getVehicle", () =>
    getVehicleApi(vehicleId)
  );
  console.log("vehicle : ", vehicle);

  const folderName = vehicle?.vehicle.statuses[0].history.picture;
  let fileLength = 0;
  const onClickDownload = async (folderName) => {
    // const zip = new JSZip();
    let imageUrls = [];

    // S3 버킷의 특정 폴더에 있는 파일 목록 조회
    const data = await (
      await axios.get(
        `${process.env.REACT_APP_BASE_URL}/vehicle/image/download`
      )
    ).data;
    fileLength = data.Contents.filter((item) =>
      folderName.includes(item.Key.split("/")[0])
    ).length;
    console.log("fileLength : ", fileLength);

    for (let i = 1; i <= fileLength; i++) {
      imageUrls.push(`${folderName}/${i}`);
    }
    console.log("imageUrls : ", imageUrls);

    async function fetchImage(url) {
      const response = await fetch(url);
      const blob = await response.blob();
      return blob;
    }

    const zip = new JSZip();
    const folder = zip.folder("images");

    const downloadPromises = imageUrls.map(async (url, index) => {
      const blob = await fetchImage(url);
      folder.file(`image${index + 1}.png`, blob);
    });

    // 모든 이미지를 다운로드
    await Promise.all(downloadPromises);

    // ZIP 파일 생성
    const content = await zip.generateAsync({ type: "blob" });

    // ZIP 파일을 클라이언트에게 다운로드하게 만듭니다.
    saveAs(content, "images.zip");

    // s3.listObjectsV2(params, async (err, data) => {
    //   if (err) {
    //     console.log(err, err.stack); // 에러 출력
    //   } else {
    //     console.log("data ::: ", data);
    //     fileLength = data.Contents.filter((item) =>
    //       folderName.includes(item.Key.split("/")[0])
    //     ).length;
    //     console.log("fileLength : ", fileLength);

    //     for (let i = 1; i <= fileLength; i++) {
    //       imageUrls.push(`${folderName}/${i}`);
    //     }
    //     console.log("imageUrls : ", imageUrls);

    //     async function fetchImage(url) {
    //       const response = await fetch(url);
    //       const blob = await response.blob();
    //       return blob;
    //     }

    //     const zip = new JSZip();
    //     const folder = zip.folder("images");

    //     const downloadPromises = imageUrls.map(async (url, index) => {
    //       const blob = await fetchImage(url);
    //       folder.file(`image${index + 1}.png`, blob);
    //     });

    //     // 모든 이미지를 다운로드
    //     await Promise.all(downloadPromises);

    //     // ZIP 파일 생성
    //     const content = await zip.generateAsync({ type: "blob" });

    //     // ZIP 파일을 클라이언트에게 다운로드하게 만듭니다.
    //     saveAs(content, "images.zip");
    // }
    // });
  };

  const { register, handleSubmit } = useForm();
  const { mutate: updateVehicle } = useMutation(updateVehicleApi, {
    onSuccess: () => {
      alert("수정이 완료되었습니다.");
      navigate(`/vehicle/${vehicleId}`);
    },
  });

  const onSubmit = (inputData) => {
    if (inputData.receivingDate === "") {
      alert("입고일자를 입력해주세요.");
    }
    if (inputData.releaseDate === "") {
      alert("출고일자를 입력해주세요.");
    }
    if (inputData.specifications === "") {
      alert("제원정보를 입력해주세요.");
    }
    if (inputData.repairArea === "") {
      alert("수리부위를 입력해주세요.");
    }
    if (inputData.receivingType === "") {
      alert("입고방법을 입력해주세요.");
    }
    if (inputData.remarks === "") {
      alert("특이사항을 입력해주세요.");
    }
    updateVehicle({
      id: vehicleId,
      receivingDate: inputData.receivingDate,
      releaseDate: inputData.releaseDate,
      specifications: inputData.specifications,
      repairArea: inputData.repairArea,
      receivingType: inputData.receivingType,
      remarks: inputData.remarks,
    });
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    !isLoading && (
      <Container>
        <Header hideBackBtn={false} />
        <Wrapper onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Title>
              <h1>{vehicle?.vehicle.number}</h1>
            </Title>
            <Content>
              <div className="title">현재상태</div>
              <div className="content">
                <p>
                  {vehicle.vehicle.statuses[0].step}단계 -{" "}
                  {vehicle.vehicle.statuses[0].statusName}
                </p>
                <div
                  onClick={() => {
                    onClickDownload(folderName);
                  }}
                  className="button"
                >
                  이미지 다운로드
                  <img src={downloadIcon} alt="download" />
                </div>
              </div>
              <div className="title">담당자명</div>
              <div className="content">
                {vehicle.vehicle.statuses[0].history.userName}
              </div>
              <div className="title">입고일자</div>
              <input
                type="date"
                defaultValue={vehicle.vehicle.receivingDate}
                className="content"
                {...register("receivingDate")}
              />
              <div className="title">출고일자</div>
              <input
                type="date"
                className="content"
                defaultValue={vehicle.vehicle.releaseDate}
                {...register("releaseDate")}
              />
              <div className="title">제원정보</div>
              <input
                type="text"
                className="content"
                defaultValue={vehicle.vehicle.specifications}
                {...register("specifications")}
              />
              <div className="title">수리부위</div>
              <input
                type="text"
                className="content"
                defaultValue={vehicle.vehicle.repairArea}
                {...register("repairArea")}
              />
              <div className="title">입고방법</div>
              <input
                type="text"
                className="content"
                defaultValue={vehicle.vehicle.receivingType}
                {...register("receivingType")}
              />
              <div className="title">특이사항</div>
              <textarea
                className="content"
                defaultValue={vehicle.vehicle.remarks}
                {...register("remarks")}
              ></textarea>
            </Content>
          </div>
          <History>
            <div className="title">이전 정보 내역</div>
            <ul className="content">
              {vehicle.history.map((item) => (
                <li key={item.id}>
                  <p>
                    {" "}
                    <BsDot id="dotIcon" /> {item.status.step}단계 -{" "}
                    {item.userName}
                  </p>
                  <div
                    onClick={() => {
                      onClickDownload(item.picture);
                    }}
                    className="button"
                  >
                    이미지 다운로드
                    <img src={downloadIcon} alt="download" />
                  </div>
                </li>
              ))}
            </ul>
            <div className="modifyBtn">
              <button
                id="modifyBtn"
                onClick={() => {
                  navigate(`/modify/${vehicleId}`);
                }}
                type="submit"
              >
                수정완료
              </button>
            </div>
          </History>
        </Wrapper>
      </Container>
    )
  );
}

export default VehicleModify;
