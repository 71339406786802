import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;
axios.defaults.withCredentials = true;

export const getAllStatusApi = async () => {
  return await (
    await axios.get(`${BASE_URL}/status`)
  ).data;
};
export const getStatusApi = async (id) => {
  return await (
    await axios.get(`${BASE_URL}/status/${id}`)
  ).data;
};
export const createStatusApi = async ({ step, statusName }) => {
  return await (
    await axios.post(`${BASE_URL}/status`, {
      step,
      statusName,
    })
  ).data;
};
export const deleteStatusApi = async (id) => {
  return await (
    await axios.delete(`${BASE_URL}/status/${id}`)
  ).data;
};
export const modifyStatusApi = async ({ id, step, statusName }) => {
  if (id) {
    console.log("API 단에서");
    console.log("id", id);
    console.log("step", step);
    console.log("statusName", statusName);
    return await (
      await axios.put(`${BASE_URL}/status/${id}`, {
        step,
        statusName,
      })
    ).data;
  }
};
