import React, { useState } from "react";
import styled from "styled-components";
import Header from "../components/Header";
import { useQuery } from "react-query";
import { getVehiclesApi } from "../apis/vehicle";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import EmployeeList from "../components/EmployeeList";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  .wrapper {
    height: 100%;
  }
`;
const Wrapper = styled.div`
  padding: 0 1.5rem;
  height: 100%;
`;
const Title = styled.div`
  h1 {
    font-weight: 700;
    font-size: 1.875rem;
    margin-bottom: 1rem;
  }
  .desc {
    p {
      font-size: 0.8rem;
      font-weight: 400;
      word-break: keep-all;
      &:last-child {
        margin-top: 0.3rem;
        font-weight: 200;
        line-height: 1.2;
      }
    }
  }
`;
const Contents = styled.div`
  height: 100%;
`;

function Employee() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Container>
      <div className="wrapper">
        <Header hideBackBtn={true} />
        <Wrapper>
          <Title>
            <h1>직원 정보 관리</h1>
            <div className="desc">
              <p>가입한 직원의 전체 목록을 확인할 수 있습니다.</p>
              <p>
                지금까지 가입한 전 직원 목록을 확인하고, 탈퇴를 진행할 수
                있습니다.
              </p>
            </div>
          </Title>
          <Contents>
            <EmployeeList />
          </Contents>
        </Wrapper>
      </div>
    </Container>
  );
}

export default Employee;
