import React, { useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { getVehiclesApi } from "../apis/vehicle";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAllStatusApi } from "../apis/status";
import { useRecoilValue } from "recoil";
import { userState } from "../atom";
import { FaSearch } from "react-icons/fa";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import Loading from "./Loading";

const Wrapper = styled.div``;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 0.5rem;
  margin-top: 1rem;
  input {
    width: 100%;
    background-color: transparent;
    outline: none;
    margin-left: 0.8rem;
    font-size: 0.9rem;
  }
`;

const DateBar = styled.div`
  margin-top: 1rem;
  .desc {
    p {
      font-size: 0.8rem;
      font-weight: 400;
      word-break: keep-all;
    }
  }
  .dateBox {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 0.3rem;
    grid-column-gap: 1rem;
    input {
      width: 100%;
      border: 1px solid #000;
      padding: 0.3rem;
      font-size: 0.9rem;
      outline: none;
      &::-webkit-calendar-picker-indicator {
        cursor: pointer;
      }
    }
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 2rem 0;
  justify-content: center;
  text-align: center;
  .head {
    border-bottom: 1px solid #000;
    padding-bottom: 0.5rem;
    font-size: 0.9rem;
    font-weight: 700;
  }
  .content {
    font-size: 0.8rem;
    word-break: keep-all;
    margin-top: 1rem;
  }
`;
const SelectBar = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  position: fixed;
  bottom: 8rem;
  left: 50%;
  transform: translateX(-50%);
  word-break: keep-all;
  button {
    background-color: #d9d9d9;
    padding: 0.3rem 0;
    border: none;
    margin-right: 0.1rem;
    width: 4rem;
    font-size: 0.75rem;
    :last-child {
      margin-right: 0;
    }
    &.active {
      background-color: #ece049;
      font-weight: 600;
    }
  }
`;

function AllVehicles() {
  const { isLoading: vehiclesIsLoading, data: vehicles } = useQuery(
    "getVehicles",
    getVehiclesApi
  );
  console.log(vehicles);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("status") || "";

  const { isLoading: statusIsLoading, data: statuses } = useQuery(
    "getStatuses",
    getAllStatusApi
  );

  const userInfo = useRecoilValue(userState);

  // 기간 설정
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // 검색바 관련
  const [query, setQuery] = useState("");
  const [showAll, setShowAll] = useState(false);

  let filteredVehicles = [];
  dayjs.extend(isBetween);
  if (showAll) {
    filteredVehicles = vehicles;
  }

  if (startDate && endDate) {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    filteredVehicles = vehicles.filter((vehicle) => {
      return dayjs(vehicle?.releaseDate).isBetween(start, end, "day", "[]");
    });
  } else {
    if (query) {
      filteredVehicles = vehicles;
    }
  }

  if (query) {
    filteredVehicles = filteredVehicles?.filter((vehicle) => {
      return (
        vehicle?.statuses[0]?.history?.userName.includes(query) ||
        vehicle?.number.includes(query)
      );
    });
  }

  filteredVehicles = status
    ? filteredVehicles?.filter(
        (vehicle) => String(vehicle?.statuses[0]?.step) === String(status)
      )
    : filteredVehicles;

  const onClickSearch = () => {
    if (!query) {
      setShowAll(true);
    }
  };

  if (statusIsLoading || vehiclesIsLoading) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <SearchBar>
        <FaSearch className="icon" onClick={onClickSearch} />
        <input
          type="text"
          placeholder="담당자명 또는 차량번호를 검색하세요"
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          value={query}
        />
      </SearchBar>
      <DateBar>
        <div className="desc">
          <p>조회할 차량의 출고일 기간을 선택해주세요.</p>
        </div>
        <div className="dateBox">
          <input
            type="date"
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
            value={startDate}
          />
          <input
            type="date"
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
            value={endDate}
          />
        </div>
      </DateBar>
      <Container>
        <div className="head">차량번호</div>
        <div className="head">입고일자</div>
        <div className="head">출고일자</div>
        <div className="head">담당자명</div>
        <div className="head">진행상황</div>
        {filteredVehicles?.map((vehicle) => (
          <React.Fragment key={vehicle?.id}>
            <div
              className="content"
              onClick={() => {
                navigate(`/vehicle/${vehicle?.id}`);
              }}
              style={{ cursor: "pointer" }}
            >
              {vehicle?.number}
            </div>
            <div className="content">
              {vehicle?.receivingDate.slice(2).replace(/-/g, ".")}
            </div>
            <div className="content">
              {vehicle?.releaseDate.slice(2).replace(/-/g, ".")}
            </div>
            <div className="content">
              {vehicle?.statuses[0]?.history?.userName}
            </div>
            <div className="content">
              {vehicle?.statuses[0]?.step}단계{`\n`}(
              {vehicle?.statuses[0]?.statusName})
            </div>
          </React.Fragment>
        ))}
      </Container>
      <SelectBar>
        {statuses?.map((item) => (
          <button
            className={String(status) === String(item.step) ? "active" : ""}
            key={item.id}
            onClick={() => {
              navigate(`/main/all?status=${item.step}`);
            }}
          >
            <p>{item.step}단계</p>
            <p>{item.statusName}</p>
          </button>
        ))}
        <button
          className={!status ? "active" : ""}
          onClick={() => {
            navigate(`/main/all`);
          }}
        >
          전체
        </button>
      </SelectBar>
    </Wrapper>
  );
}

export default AllVehicles;
