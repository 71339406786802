import React from "react";
import styled from "styled-components";
import loadingLottie from "../assets/video/loading.json";
import Lottie from "react-lottie";

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
`;

function Loading() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Container>
      <Lottie
        options={defaultOptions}
        height={200}
        width={200}
        isClickToPauseDisabled={true}
      />
    </Container>
  );
}

export default Loading;
