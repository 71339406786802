import React from "react";
import styled from "styled-components";
import logo from "../assets/img/logo.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { checkLoginApi, loginApi } from "../apis/auth";
import { useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { userState } from "../atom";
import Loading from "../components/Loading";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-size: 1.125rem;
  .logoGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6rem;
    img {
      width: 10rem;
      margin-bottom: 1.5rem;
    }
    h1 {
      text-align: center;
      &:first-child {
        margin-bottom: 0.5rem;
      }
    }
  }
  .formGroup {
    display: flex;
    flex-direction: column;
    input,
    button {
      ${(props) => props.theme.buttonFormat}
    }
    input {
      background-color: #f5f5f5;
      font-size: 0.9375rem;
      padding-left: 1rem;
      &:first-child {
        margin-bottom: 0.5rem;
      }
      &:focus {
        outline: none;
      }
    }
    button {
      background-color: #ece049;
      margin-top: 1rem;
      font-size: 1.125rem;
      color: #000;
    }
  }
  .signupLink {
    margin-top: 3rem;
    h3 {
      font-size: 0.875rem;
      font-weight: 200;
      text-align: center;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;
const Wrapper = styled.div``;

function Login() {
  const navigate = useNavigate();

  const { isLoading, data } = useQuery("checkLogin", checkLoginApi, {
    onSuccess: (res) => {
      console.log("로그인되어 있음", res);
      navigate("/main");
    },
    onError: (err) => {
      console.log("로그인되어 있지 않음", err);
    },
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const requestUrl = searchParams.get("requestUrl");

  const { register, handleSubmit, setValue } = useForm();
  const { mutate: login } = useMutation(loginApi, {
    onSuccess: (res) => {
      // 로그인 성공
      if (res.isLoggedIn === true) {
        if (requestUrl) {
          return (window.location.href = requestUrl);
        } else {
          return navigate("/main");
        }
      }

      // 로그인 실패
      else {
        setValue("pw", "");
        setValue("id", "");
        console.log(res);
        if (res.message === "Incorrect ID") {
          return alert("아이디가 존재하지 않습니다.");
        }
        if (res.message === "Incorrect password") {
          return alert("비밀번호가 일치하지 않습니다.");
        } else {
          return alert("로그인에 실패했습니다. 다시 한 번 시도해주세요.");
        }
      }
    },
    onError: (err) => {
      console.log(err);
      return alert("로그인에 실패했습니다. 다시 한 번 시도해주세요.");
    },
  });

  const onSubmit = (inputData) => {
    if (!inputData.id) {
      return alert("아이디를 입력해주세요.");
    } else if (!inputData.pw) {
      return alert("비밀번호를 입력해주세요.");
    }
    login({
      id: inputData.id,
      pw: inputData.pw,
    });
  };

  return (
    <Container>
      <Wrapper>
        <div className="logoGroup">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
        </div>
        <form className="formGroup" onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            autoComplete="off"
            placeholder="아이디"
            {...register("id")}
          />
          <input
            type="password"
            autoComplete="off"
            placeholder="비밀번호"
            {...register("pw")}
          />
          <button className="login" type="submit">
            로그인
          </button>
        </form>
        <div className="signupLink">
          <h3
            onClick={() => {
              navigate("/signup");
            }}
          >
            계정이 없으신가요? 회원가입 하러 가기
          </h3>
        </div>
      </Wrapper>
    </Container>
  );
}

export default Login;
