import React from "react";
import { useQuery } from "react-query";
import { Outlet, Navigate } from "react-router-dom";
import { checkLoginApi } from "../../apis/auth";
import Loading from "../Loading";
import { useSetRecoilState } from "recoil";
import { userState } from "../../atom";

function PrivateRoute({ isAdmin }) {
  const requestUrl = window.location.href;

  const setUserState = useSetRecoilState(userState);
  const { isLoading, data } = useQuery("checkLogin", checkLoginApi, {
    onSuccess: (res) => {
      console.log("로그인되어 있음", res);
      setUserState(res.user);
    },
    onError: (err) => {
      console.log("로그인되어 있지 않음", err);
      setUserState(null);
    },
  });

  if (isAdmin) {
    if (data && !data?.user?.isAdmin) {
      alert("관리자만 접근 가능합니다.");
      return <Navigate to="/" />;
    }
  }

  if (isLoading) {
    return <Loading />;
  } else {
    if (data && data?.isLoggedIn === true) {
      return <Outlet />;
    } else {
      // alert("로그인이 필요합니다.");
      return <Navigate to={`/login?requestUrl=${requestUrl}`} />;
    }
  }
}

export default PrivateRoute;
