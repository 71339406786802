import React from "react";
import styled from "styled-components";
import logo from "../assets/img/logo.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { checkLoginApi, loginApi, logoutApi } from "../apis/auth";
import { useForm } from "react-hook-form";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userState } from "../atom";
import Loading from "../components/Loading";
import { updateUserApi } from "../apis/user";
import {
  createStatusApi,
  deleteStatusApi,
  getAllStatusApi,
  modifyStatusApi,
} from "../apis/status";
import { AiFillDelete } from "react-icons/ai";
import Header from "../components/Header";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: 1.125rem;
  padding: 3rem 0;
  .logoGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
    img {
      width: 10rem;
      margin-bottom: 1.5rem;
    }
    h1 {
      text-align: center;
      font-weight: 700;
      &:first-child {
        margin-bottom: 0.5rem;
      }
    }
  }
`;
const Statuses = styled.div``;
const Status = styled.form`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  justify-content: space-between;
  .step {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    p {
      margin: 0 0.5rem;
    }
    input {
      display: block;
      width: max-content;
      &.stepNumber {
        width: 2rem;
        text-align: center;
      }
    }
  }
  input {
    border: 1px solid #000;
    height: min-content;
    padding: 0.2rem;
    font-size: 0.9rem;
    &.step {
      width: 2rem;
      text-align: center;
    }
    &.name {
      width: 12rem;
    }
    &:last-child {
    }
  }
  .icon {
    margin-left: 0.5rem;
  }
  button.plus {
    border: none;
    background-color: #fff;
    font-size: 1rem;
    display: block;
    text-align: center;
    font-weight: 700;
    &:hover {
      cursor: pointer;
    }
  }
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
  padding-top: 1rem;
  button {
    background-color: #ece049;
    padding: 0.5rem;
    cursor: pointer;
  }
`;

function StatusModify() {
  const navigate = useNavigate();
  const { register, handleSubmit, setValue } = useForm();

  const {
    isLoading: statusIsLoading,
    data: statuses,
    refetch,
  } = useQuery("getAllStatus", getAllStatusApi);
  console.log(statuses);

  const { mutate: deleteStatus } = useMutation(deleteStatusApi, {
    onSuccess: (res) => {
      console.log(res);
      refetch();
      return alert("삭제되었습니다.");
    },
  });
  const { mutate: createStatus } = useMutation(createStatusApi, {
    onSuccess: (res) => {
      console.log(res);
      refetch();
      return alert("추가되었습니다.");
    },
  });
  const { mutate: modifyStatus } = useMutation(modifyStatusApi, {
    onSuccess: (res) => {
      console.log(res);
      refetch();
      return alert("수정되었습니다.");
    },
  });

  const onClickDelete = (id) => {
    const confirm = window.confirm(`정말 삭제하시겠습니까?`);
    if (confirm) {
      deleteStatus(id);
    }
  };

  const onSubmit = (inputData) => {
    if (!inputData.step) {
      return alert("단계를 입력해주세요.");
    }
    if (!inputData.statusName) {
      return alert("상태명을 입력해주세요.");
    }
    createStatus(inputData);
    setValue("step", "");
    setValue("statusName", "");
  };

  const onSubmitModify = (inputData, id) => {
    console.log({ inputData, id });
    if (!inputData[`step${id}`]) {
      return alert("단계를 입력해주세요.");
    }
    if (!inputData[`statusName${id}`]) {
      return alert("상태명을 입력해주세요.");
    }
    console.log({
      id,
      step: inputData[`step${id}`],
      statusName: inputData[`statusName${id}`],
    });
    modifyStatus({
      id,
      step: Number(inputData[`step${id}`]),
      statusName: inputData[`statusName${id}`],
    });

    // setValue(`step${id}`, "");
    // setValue(`statusName${id}`, "");
  };

  if (statusIsLoading) {
    return <Loading />;
  }

  return (
    <Container>
      {/* <Header /> */}
      <div className="logoGroup">
        <div className="logo">
          <img src={logo} alt="logo" />
          <h1>작업 단계 정보 추가</h1>
        </div>
      </div>
      <Statuses>
        {statuses &&
          statuses.map((status) => (
            <Status
              key={status.id}
              onSubmit={handleSubmit((inputData) =>
                onSubmitModify(inputData, status.id)
              )}
            >
              <div className="step">
                <input
                  className="stepNumber"
                  type="number"
                  defaultValue={status.step}
                  {...register(`step${status.id}`)}
                />
                <p>단계 -</p>
                <input
                  className="statusName"
                  type="text"
                  defaultValue={status.statusName}
                  {...register(`statusName${status.id}`)}
                />
              </div>
              <button
                onClick={() => {
                  modifyStatus(status.id);
                }}
              >
                수정
              </button>
              <AiFillDelete
                className="icon"
                onClick={() => {
                  onClickDelete(status.id);
                }}
              />
            </Status>
          ))}
        <Status onSubmit={handleSubmit(onSubmit)}>
          <input
            type="number"
            className="step"
            {...register("step")}
            autoComplete="off"
          />
          <p>단계 -</p>
          <input
            type="text"
            className="name"
            {...register("statusName")}
            autoComplete="off"
          />
          <button className="plus">추가</button>
        </Status>
      </Statuses>
    </Container>
  );
}

export default StatusModify;
