import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;
axios.defaults.withCredentials = true;

export const getVehiclesApi = async () => {
  return await (
    await axios.get(`${BASE_URL}/vehicle`)
  ).data;
};

export const createVehicleApi = async ({
  number,
  receivingDate,
  releaseDate,
  specifications,
  repairArea,
  receivingType,
  remarks,
  currentStatusId,
  userName,
  picture,
}) => {
  return await (
    await axios.post(`${BASE_URL}/vehicle`, {
      number,
      receivingDate,
      releaseDate,
      specifications,
      repairArea,
      receivingType,
      remarks,
      currentStatusId,
      userName,
      picture,
    })
  ).data;
};

export const getVehicleApi = async (id) => {
  return await (
    await axios.get(`${BASE_URL}/vehicle/search/${id}`)
  ).data;
};

export const updateVehicleApi = async ({
  id,
  receivingDate,
  releaseDate,
  specifications,
  repairArea,
  receivingType,
  remarks,
}) => {
  return await (
    await axios.put(`${BASE_URL}/vehicle/${id}`, {
      receivingDate,
      releaseDate,
      specifications,
      repairArea,
      receivingType,
      remarks,
    })
  ).data;
};

export const getVihicleByNumberApi = async (number) => {
  return await (
    await axios.get(`${BASE_URL}/vehicle/number?number=${number}`)
  ).data;
};
