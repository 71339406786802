import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { getVehiclesApi } from "../apis/vehicle";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAllStatusApi } from "../apis/status";
import { deleteUserApi, getUsersApi } from "../apis/user";
import { useRecoilValue } from "recoil";
import { userState } from "../atom";
import { FaSearch } from "react-icons/fa";

const Wrapper = styled.div``;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 0.5rem;
  margin-top: 1rem;
  input {
    width: 100%;
    background-color: transparent;
    outline: none;
    margin-left: 0.8rem;
    font-size: 0.9rem;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 2rem 0;
  justify-content: center;
  text-align: center;
  align-items: center;
  .head {
    border-bottom: 1px solid #000;
    padding-bottom: 0.5rem;
    font-size: 0.9rem;
    font-weight: 700;
    &.invisible {
      color: transparent;
    }
  }
  .content {
    font-size: 0.8rem;
    word-break: keep-all;
    margin-top: 1rem;
  }
  #unsubscribeBtn {
    padding: 0.2rem 0.3rem;
    border: none;
    background-color: #ece049;
    border-radius: 3px;
  }
`;

function EmployeeList() {
  const navigate = useNavigate();
  // 검색바 관련
  const [query, setQuery] = useState("");

  const { data: users, refetch } = useQuery("getUsers", getUsersApi);
  console.log(users);
  const userInfo = useRecoilValue(userState);

  let filteredUsers = [];
  filteredUsers = users?.filter((user) => {
    return user?.name.includes(query);
  });

  const { mutate: deleteUser } = useMutation(deleteUserApi, {
    onSuccess: () => {
      refetch();
    },
  });

  const onClickDelete = (name, id) => {
    const confirm = window.confirm(`${name}님을 탈퇴 처리하시겠습니까?`);
    if (confirm) {
      deleteUser(id);
      alert(`탈퇴 처리되었습니다.`);
    }
  };

  return (
    <Wrapper>
      <SearchBar>
        <FaSearch className="icon" />
        <input
          type="text"
          placeholder="직원명을 검색하세요."
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          value={query}
        />
      </SearchBar>
      <Container>
        <div className="head">이름</div>
        <div className="head">아이디</div>
        <div className="head">가입일자</div>
        <div className="head invisible">n</div>
        {filteredUsers?.map((user) => (
          <React.Fragment key={user?.id}>
            <div className="content">{user?.name}</div>
            <div className="content">{user?.accountName}</div>
            <div className="content">{user?.createdAt.slice(0, 10)}</div>
            <div className="content">
              {userInfo?.id === user?.id ? (
                <></>
              ) : (
                <button
                  id="unsubscribeBtn"
                  onClick={() => {
                    onClickDelete(user?.name, user?.id);
                  }}
                >
                  탈퇴
                </button>
              )}
            </div>
          </React.Fragment>
        ))}
      </Container>
    </Wrapper>
  );
}

export default EmployeeList;
