import React, { useState } from "react";
import styled from "styled-components";
import Header from "../components/Header";
import { useMutation, useQuery } from "react-query";
import {
  createVehicleApi,
  getVehiclesApi,
  getVihicleByNumberApi,
} from "../apis/vehicle";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getAllStatusApi, getStatusApi } from "../apis/status";
import AWS from "aws-sdk";
import dayjs from "dayjs";
import axios from "axios";
import uploadIcon from "../assets/img/upload.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* justify-content: space-between; */
  .contentsWrapper {
    margin-bottom: 10rem;
  }
`;
const Wrapper = styled.div`
  padding: 0 1.5rem;
`;
const Title = styled.div`
  h1 {
    font-weight: 700;
    font-size: 1.875rem;
    margin-bottom: 1rem;
  }
  .desc {
    p {
      font-size: 0.8rem;
      font-weight: 400;
      word-break: keep-all;
      &:last-child {
        margin-top: 0.3rem;
        font-weight: 200;
        line-height: 1.2;
      }
    }
  }
`;
const Contents = styled.div`
  display: grid;
  /* 1대 3 비율 */
  grid-template-columns: 1fr 3fr;
  margin: 2rem 0;
  grid-row-gap: 1rem;
  font-size: 0.9375rem;
  align-items: center;
  .title {
    font-weight: 700;
  }
  .content.fileUpload {
    display: flex;
    align-items: center;
    font-size: 0.6875rem;
    .uploadBtn {
      margin-left: 0.5rem;
      label {
        background-color: #ece049;
        padding: 0.1rem 0.3rem;
        border-radius: 3px;
        img {
          width: 0.5rem;
          margin-left: 0.1rem;
        }
      }
      p {
        margin-top: 0.2rem;
      }
    }
  }
  #searchByNumberBtn {
    display: inline-block;
    background-color: #ece049;
    padding: 0.1rem 0.3rem;
    border-radius: 3px;
    border: none;
    font-size: 0.6875rem;
    margin-left: 0.5rem;
  }
  #fileUploadBtn {
    display: none;
  }
  input,
  textarea,
  select {
    outline: none;
    font-size: 0.9375rem;
    font-weight: 400;
    border: 0.5px solid #000;
    padding: 0.3rem;
  }
  input,
  select {
    width: 9rem;
  }
  textarea {
    resize: none;
    height: 5rem;
    width: 14rem;
  }
`;
const SelectBar = styled.div``;
const Footer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
`;
const Buttons = styled.div`
  display: flex;
  button {
    background-color: #333333;
    border: none;
    color: #fff;
    width: 100%;
    cursor: pointer;
    font-size: 1.25rem;
    word-break: keep-all;
    padding: 2rem 1rem;
    font-weight: 700;
    &:last-child {
      background-color: #ece049;
      color: #000;
    }
  }
`;

function Enroll() {
  const navigate = useNavigate();
  const location = useLocation();
  const { register, handleSubmit, getValues } = useForm();
  const [existData, setExistData] = useState({});

  // 띄어쓰기 막기
  const handleKeyPress = (e) => {
    if (e.keyCode === 32) {
      e.preventDefault();
    }
  };

  // status 조회
  const { data: allStatus } = useQuery("getAllStatus", getAllStatusApi);

  const today = dayjs().format("YYYYMMDD-HHmmss");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleFileChange = (e) => {
    // 이미지 파일만 업로드 가능
    if (!e.target.files[0].type.includes("image")) {
      return alert("이미지 파일만 업로드 가능합니다.");
    }
    const files = Array.from(e.target.files); // FileList를 배열로 변환
    setSelectedFiles(files);
  };

  // S3 이미지 업로드 => 서버 측으로 옮기기.
  AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });
  const s3 = new AWS.S3();
  let folderName = "";
  const uploadImage = ({ number, userName, status, files }) => {
    folderName = `${number}_${userName}_${status}_${today}`;
    // const uploadPromises = files.map((file, i) => {
    //   const fileName = `${folderName}/${i + 1}`;
    //   const params = {
    //     Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    //     Key: fileName,
    //     Body: file,
    //     ContentType: "image/png",
    //     ACL: "public-read",
    //   };

    //   return s3.upload(params).promise();
    // });
    const uploadPromises = files.map(async (file, i) => {
      const fileName = `${folderName}/${i + 1}`;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", fileName);
      const res = await (
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/vehicle/image/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
      ).data;
      console.log(res);
      return res;
      // return s3.upload(params).promise();
    });

    Promise.all(uploadPromises)
      .then((results) => {
        console.log("이미지 업로드 완료. ", results);
        return results;
      })
      .catch((err) => {
        console.error("Error uploading files", err);
      });
  };

  // 차량 정보 등록
  const { mutate: createVehicle } = useMutation(createVehicleApi, {
    onSuccess: (res) => {
      if (res.error === "notExistUser") {
        return alert("존재하지 않는 사용자입니다. 담당자명을 확인해주세요.");
      }
      alert("차량 정보 등록이 완료되었습니다.");
      return navigate("/main");
    },
    onError: (error) => {
      console.log(error);
      return alert("차량 정보 등록에 실패했습니다. 다시 시도해주세요.");
    },
  });

  // useState로 각 state 등록. default value는 existData의 값이 있으면 그 값을 넣어주고, 없으면 빈문자열로 초기화.
  const [number, setNumber] = useState(existData.number || "");
  const [currentStatusId, setCurrentStatusId] = useState(
    existData.currentStatusId || ""
  );
  const [userName, setUserName] = useState(existData.userName || "");
  const [receivingDate, setReceivingDate] = useState(
    existData.receivingDate || ""
  );
  const [releaseDate, setReleaseDate] = useState(existData.releaseDate || "");
  const [specifications, setSpecifications] = useState(
    existData.specifications || ""
  );
  const [repairArea, setRepairArea] = useState(existData.repairArea || "");
  const [receivingType, setReceivingType] = useState(
    existData.receivingType || ""
  );
  const [remarks, setRemarks] = useState(existData.remarks || "");

  console.log("하하하하하", number);

  const onSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = [
      { value: number, message: "차량번호를 입력해주세요." },
      { value: currentStatusId, message: "현재상태를 입력해주세요." },
      { value: userName, message: "담당자명을 입력해주세요." },
      { value: receivingDate, message: "입고일자를 입력해주세요." },
      { value: releaseDate, message: "출고일자를 입력해주세요." },
      { value: specifications, message: "제원정보를 입력해주세요." },
      { value: repairArea, message: "수리부위를 입력해주세요." },
      { value: receivingType, message: "입고방법을 입력해주세요." },
      { value: remarks, message: "특이사항을 입력해주세요." },
    ];

    for (let field of requiredFields) {
      if (!field.value) {
        alert(field.message);
        return;
      }
    }

    if (!selectedFiles || selectedFiles.length === 0) {
      return alert("이미지를 업로드해주세요.");
    }

    // console.log(inputData);

    const currentStatus = await (
      await axios.get(
        `${process.env.REACT_APP_BASE_URL}/status/${currentStatusId}`
      )
    ).data;

    // s3 이미지 업로드
    uploadImage({
      number,
      userName,
      status: currentStatus.status.statusName,
      files: selectedFiles,
    });

    console.log("수리부위 바뀌는지 체크", repairArea, remarks);
    createVehicle({
      number,
      currentStatusId,
      userName,
      receivingDate,
      releaseDate,
      specifications,
      repairArea,
      receivingType,
      remarks,
      picture: `${process.env.REACT_APP_AWS_S3_BASE_URL}/${folderName}`,
    });
  };

  // 차량 번호로 조회
  const { mutate: getVehicleByNumber } = useMutation(getVihicleByNumberApi, {
    onSuccess: (res) => {
      if (res.error === "notExistVehicle") {
        return alert("존재하지 않는 차량번호입니다.\n새로 등록해주세요.");
      }
      console.log(res);
      setExistData(res.vehicle);
      // 각 state 설정
      setNumber(res.vehicle.number);
      setCurrentStatusId(res.vehicle.currentStatusId);
      setUserName(res.vehicle.userName);
      setReceivingDate(res.vehicle.receivingDate);
      setReleaseDate(res.vehicle.releaseDate);
      setSpecifications(res.vehicle.specifications);
      setRepairArea(res.vehicle.repairArea);
      setReceivingType(res.vehicle.receivingType);
      setRemarks(res.vehicle.remarks);
      return alert("차량 정보 조회가 완료되었습니다.");
    },
    onError: (err) => {
      console.log(err);
    },
  });
  const onClickSearchByNumber = () => {
    if (!number) {
      return alert("차량번호를 입력해주세요.");
    }
    getVehicleByNumber(number);
  };
  console.log({ existData });

  const handleNumberChange = (e) => {
    setNumber(e.target.value);
  };
  const handleCurrentStatusIdChange = (e) => {
    setCurrentStatusId(e.target.value);
  };
  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };
  const handleReceivingDateChange = (e) => {
    setReceivingDate(e.target.value);
  };
  const handleReleaseDateChange = (e) => {
    setReleaseDate(e.target.value);
  };
  const handleSpecificationsChange = (e) => {
    setSpecifications(e.target.value);
  };
  const handleRepairAreaChange = (e) => {
    setRepairArea(e.target.value);
  };
  const handleReceivingTypeChange = (e) => {
    setReceivingType(e.target.value);
  };
  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };

  return (
    <Container>
      <Header hideBackBtn={false} />
      <form onSubmit={onSubmit}>
        <div className="contentsWrapper">
          <Wrapper>
            <Title>
              <h1>차량 정보 등록</h1>
              <div className="desc">
                <p>새로운 차량 정보를 등록할 수 있습니다.</p>
                <p>
                  아래 모든 정보를 입력해야 차량 정보 등록을 완료할 수 있으며,
                  이미지 업로드를 필수로 진행해주세요.
                </p>
              </div>
            </Title>
            <Contents>
              <div className="title">차량번호</div>
              <div className="content">
                <input
                  type="text"
                  autoComplete="off"
                  onKeyDown={handleKeyPress}
                  onChange={handleNumberChange}
                  value={number}
                />
                <div id="searchByNumberBtn" onClick={onClickSearchByNumber}>
                  조회
                </div>
              </div>
              <div className="title">현재상태</div>
              <div className="content fileUpload">
                <select
                  onChange={handleCurrentStatusIdChange}
                  value={currentStatusId}
                >
                  <option value="">현재 상태 선택</option>
                  {allStatus?.map((status) => (
                    <option value={status?.id} key={status?.id}>
                      {status?.step}단계 - {status?.statusName}
                    </option>
                  ))}
                </select>
                <input
                  type="file"
                  id="fileUploadBtn"
                  multiple
                  onChange={handleFileChange}
                />
                <div className="uploadBtn">
                  <label htmlFor="fileUploadBtn">
                    이미지 업로드 <img src={uploadIcon} alt="upload" />
                  </label>
                  {selectedFiles && selectedFiles.length > 0 && (
                    <p>{selectedFiles.length}개의 파일이 선택됨</p>
                  )}
                </div>
              </div>
              <div className="title">담당자명</div>
              <div className="content">
                <input
                  type="text"
                  autoComplete="off"
                  onChange={handleUserNameChange}
                  value={userName}
                />
              </div>
              <div className="title">입고일자</div>
              <div className="content">
                <input
                  type="date"
                  autoComplete="off"
                  onChange={handleReceivingDateChange}
                  value={receivingDate}
                />
              </div>
              <div className="title">출고일자</div>
              <div className="content">
                <input
                  type="date"
                  autoComplete="off"
                  onChange={handleReleaseDateChange}
                  value={releaseDate}
                />
              </div>
              <div className="title">제원정보</div>
              <div className="content">
                <input
                  type="text"
                  autoComplete="off"
                  onChange={handleSpecificationsChange}
                  value={specifications}
                />
              </div>
              <div className="title">수리부위</div>
              <div className="content repairArea">
                <textarea
                  type="text"
                  autoComplete="off"
                  onChange={handleRepairAreaChange}
                  value={repairArea}
                />
              </div>
              <div className="title">입고방법</div>
              <div className="content">
                <input
                  type="text"
                  autoComplete="off"
                  onChange={handleReceivingTypeChange}
                  value={receivingType}
                />
              </div>
              <div className="title">특이사항</div>
              <div className="content">
                <textarea
                  autoComplete="off"
                  onChange={handleRemarksChange}
                  value={remarks}
                />
              </div>
            </Contents>
          </Wrapper>
        </div>
        <Footer>
          <SelectBar></SelectBar>
          <Buttons>
            <button
              onClick={(e) => {
                navigate("/main");
              }}
            >
              <p>취소</p>
            </button>
            <button type="submit">
              <p>등록 완료</p>
            </button>
          </Buttons>
        </Footer>
      </form>
    </Container>
  );
}

export default Enroll;
